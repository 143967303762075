.Steps {
    background-color: white;
    color: #005C8F;
}

.Steps h2 {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

/* .Steps .steps-items {
    background-image: url('../../../assets/images/Steps_1.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 40%;
} */

.steps-image-container {
    display: flex;
    justify-content: center;
}

.steps-image {
    max-width: 80%;
}

@media (max-width: 600px) {

    .Steps h2 {
        font-size: large;
        margin-top: 2%;
        padding: 0;
    }

    .Steps .steps-items {
        display: none;
    }

    .steps-image-container {
        padding: 2% 10%;
        justify-content: left;
        overflow: scroll;
    }

    .Steps .steps-image {
        /* display: block; */
        max-width: 300%;
        max-height: 500px;
    }
}