.AboutUs {
    background-image: url(../../../assets/images/Fundo.png);
    background-position: center;
    background-size: cover;

    /* background: rgb(255,255,255); */
    /* background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 50%, rgba(230,230,230,1) 100%); */
}

.aboutUs-items {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.aboutUs--img {
    width: 100%;
    margin-bottom: 20px;
}

.aboutUs--img img {
    width: 100%;
    margin-top: 20px;
    box-shadow: 3px 3px 10px #000000;
}

.aboutUs--content {
    text-align: center;
}

.aboutUs--content h2 {
    margin-bottom: 30px;
}

.aboutUs--content a {
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px;
    color: #135463;
    border: 3px solid #135463;
    background-color: transparent;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.5s;
    box-shadow: 3px 5px 5px rgb(122, 122, 122);
}

.aboutUs--content a:hover {
    color: white;
    background-color: #135463;
    border: 3px solid transparent;
    transition: 0.5s;
}

.aboutUs--content a:active {
    transition: 0.1s;
    box-shadow: 3px 5px 5px rgb(70, 70, 70);
    transform: translate(2px, 1px);
}