

.NewPassword {
    height: 100vh;
    background: linear-gradient(135deg, #c7f5ff, #5fb8cc, #005e72);
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-senha {
    width: 70%;
    border-radius: 25px;
    box-shadow: 4px 6px 2px 2px rgb(150, 140, 140);
    padding: 2% 8%;
    height: max-content;
    background-color: rgba(255, 255, 255, 0.767);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
}

.card-senha h2 {
    text-align: center;
    padding: 2% 10% 5% 10%;
    color: rgb(34, 34, 34);
    font-size: 30px;
}

.input-senha {
    width: 80%;
    height: 20%;
    padding: 3% 0;
    display: flex;
    flex-direction: column;
}

.input-senha label {
    padding-left: 15px;
    font-size: 16px;
    font-weight: 500;
}

.input-senha input {
    height: 35px;
    padding-left: 15px;
    border-radius: 30px;
    border-color: rgba(81, 136, 146, 0.76);
}

.input-senha input:focus {
    outline-color: #005e72;
}

.card-senha button {
    cursor: pointer;
    background-color: rgb(55, 158, 172);
    border: none;
    border-radius: 10px;
    padding: 3%;
    margin-top: 5%;
    font-weight: bold;
}

@media (max-width:1850px) {
    .card-senha {
        width: 30%;
    }
}

@media (max-width:1500px) {
    .card-senha {
        width: 40%;
    }
}

@media (max-width:1200px) {
    .card-senha {
        width: 45%;
    }
}