.Social {
    color: white;
    background: rgb(0, 57, 69);
    background: linear-gradient(90deg, rgba(0, 57, 69, 1) 0%, rgba(0, 94, 114, 1) 35%, rgba(0, 141, 171, 1) 100%);
}

.social-participation {
    padding: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.social--text {
    width: 70%;
    margin-top: 20px;
    margin-bottom: 50px;
}

.social--text h2 {
    text-align: center;
    padding-bottom: 30px;
}

.social--text p {
    text-align: justify;
}

.social--img img {
    width: 350px;
    height: 300px;
}

@media (max-width:600px) {
    .social--text p {
        width: 100%;
        margin: 0;
        text-align: justify;
    }

    .social--text h2 {
        margin: 0;
        text-align: center;
    }
}