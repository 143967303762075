.password {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.575);
    user-select: none;
    background-color: rgb(250, 250, 250);
}

.password .passwordInput {
    padding: 10px;

    width: 100%;
    height: 100%;
    background-color: transparent;
    margin: auto;
    border: 0;
    font-size: larger;
}

.password .passwordInput:focus {
    outline: none;
}

.password .passwordCheck {
    display: none;
}

.password .passwordToggle {
    border-left: 1px solid rgba(0, 0, 0, 0.575);
    display: inline-block;
    padding: 10px;
    transition: background-color 0.5s;
    border-radius: 0 5px 5px 0;

}

.password .passwordToggle:hover {
    background: rgba(220, 216, 245, 0.384);
    transition: background-color 0.5s;
}