.download-component {
    margin-top: 8px;
    display: block;
}

.download {
    cursor: pointer;
    color: #054679;
    background-color: transparent;
    text-decoration: none;
    border: 1px solid #054679;
    padding: 7px 10px;
    border-radius: 5px;
    transition: 0.5s;
    margin-left: 10px;
}

.download:hover {
    color: white;
    background-color: #005e72;
    transition: 0.5s
}

.selectdownload {
    height: 30px;
    cursor: pointer;
    color: #054679;
    background-color: transparent;
    text-decoration: none;
    border: 1px solid #054679;
    padding: 8px 8px;
    border-radius: 5px;
    transition: 0.5s;
    margin-left: -50px;
}