.Banner {
    padding-top: 80px;
    display: flex;
    align-items: center;
    background-image: url('../../../assets/images/Plano_de_mobilidade_capa2.png');
    background-position: bottom;
    background-size: cover;
    height: max-content;
}

.Banner .banner-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Banner .banner-items img {
    display: flex;
    width: 100%;
}

.Banner .btn {
    width: fit-content;
    margin: 3% auto;
    padding: 10px;
    background-color: #238ca3;
    border: 3px solid transparent;
    border-radius: 5px;

}

.Banner .btn a {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    text-decoration: none;
    color: white;
}

.Banner .plano-logo{
    margin-top: 6%;
}

.Banner .btn a:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.Banner .btn:hover a {
    padding-right: 25px;
}

.Banner .btn:hover a:after {
    opacity: 1;
    right: 0;
}

.Banner .carousel {
    height: 100%;
}

.Banner .carousel .slide img {
    width: 100%;
    height: 100%;
}

.carousel .control-dots {
    z-index: 0;
}

.Banner .image-wrapper {
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .Banner {
        padding-top: 100px;
    }

    .Banner .center {
        max-width: 100%;
        padding: 0;
    }

    .Banner .btn {
        width: fit-content;
        margin: 3% auto;
    }
}