.card {
  border-radius: 15px;
  margin: 10px;
  background-color: #ffffff;
  max-height: 460px;
  max-width: 550px;
  width: 100vh;
  height: 100vh;
  text-align: center;
  align-content: center;
  color: black;

}

.cardsub {
  margin-bottom: 10px;
  color: aqua;
}

.quizdisplay {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.card .poll-question-select select {
  margin-top: 10px;
  margin-bottom: 10PX;
  width: 50%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .card {
    width: auto;
    max-height: max-content;
    height: max-content;
  }

  .card .poll-question-select select {
    width: 80%;
  }
}