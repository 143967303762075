.timePicker {
    display: flex;
    width: 60%;
    margin: auto;
    border-radius: 5px;
    margin-top: 5%;
    border: 1px solid rgba(0, 0, 0, 0.575);
    user-select: none;
    background-color: rgb(250, 250, 250);
    align-items: top;
    flex-wrap: nowrap;
    flex-direction: row;
}

.timeList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: 0vh;
    width: 60%;
    transition: max-height .25s ease-out;
    margin: auto;
    box-shadow: 2px 2px 5px rgb(44, 44, 44);
    /* border: 1px solid black; */
    border-radius: 0 0 5px 5px;

}

.timeList ul {
    max-height: 0vh;
    overflow-y: scroll;
    transition: max-height .25s ease-out;
    padding: 5px;
}


.timeList ul::-webkit-scrollbar {
    width: 0.4em;
    background-color: #a6aaaaa1;
    border-radius: 10px;
}

.timeList ul::-webkit-scrollbar-thumb {
    background-color: #1f21247e;
    border-radius: 10px;
}

.timeList ul::-webkit-scrollbar-thumb:active {
    background-color: #000000;
    border-radius: 10px;
}

.timeList li {
    list-style-type: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.116);
    padding: 8px 5px;
}

.timeList li button {
    background-color: transparent;
    width: 100%;
    border: none;
    font-size: large;
    text-align: left;
    padding-left: 5px;
}

.timeList li:hover {
    background: rgba(220, 216, 245, 0.438);
    transition: background-color 0.5s;
}

.timePicker label {
    margin: 0;
}



.timePicker .clockToggle {
    border-left: 1px solid rgba(0, 0, 0, 0.575);
    display: inline-block;
    padding: 5%;
    transition: background-color 0.5s;
    border-radius: 0 5px 5px 0;
}

.timePicker .timePickerInput {
    font-size: 24px;
    border: none;
    width: 80%;
    padding: 10px;
    background-color: transparent;
}

.timePicker .clockCheck {
    display: none;
}

.timePicker .clockToggle:hover {
    background: rgba(220, 216, 245, 0.384);
    transition: background-color 0.5s;
}