.blue {
    color: blue;
}

.ResearcherForm {
    background: linear-gradient(45deg, rgb(160, 160, 245), rgb(1, 1, 63));
    height: 100vh;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.ResearcherForm .poll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    background-color: transparent;
}

.ResearcherForm .poll .form-respondent {
    width: 70%;
    border-radius: 25px;
    box-shadow: 4px 6px 2px 2px rgb(150, 140, 140);
    padding: 2% 8%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-color: rgb(235, 235, 235);
}

.ResearcherForm .poll .form-respondent .title {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.ResearcherForm .poll .form-respondent .title h4 {
    color: rgb(161, 4, 4);
    width: 100%;
}

.ResearcherForm .poll .form-respondent hr {
    border-top: 0.1px inset rgba(48, 48, 48, 0.534);
    border-bottom: 0.1px inset rgba(48, 48, 48, 0.534);
    width: 100%;
    margin-top: 10px;
}



.ResearcherForm .poll .form-respondent .poll-title hr {
    margin-top: 30px;
}



.ResearcherForm .poll .form-respondent .btn-submit {
    background-color: darkcyan;
    border: 0;
    border-radius: 5px;
}

.ResearcherForm .poll .form-respondent .btn-submit:hover {
    background-color: dodgerblue;
}

.ResearcherForm .poll .form-respondent .btn-search {
    background-color: darkcyan;
    border: 0;
    margin: auto;
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
}

.ResearcherForm .poll .form-respondent .btn-search:hover {
    background-color: dodgerblue;
}

.ResearcherForm .poll .form-respondent .form-group {
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.ResearcherForm .poll .form-respondent input {
    width: 100%;
    margin-top: 8px;
    padding: 10px 15px;

    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.575);
    user-select: none;
    background-color: rgb(250, 250, 250);

}

.ResearcherForm .poll .form-respondent input :hover {
    border: 2px solid rgba(3, 12, 139, 0.575);
}

.ResearcherForm .poll .form-respondent button {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px 40px 10px 40px;
    color: white
}

.ResearcherForm .poll .form-respondent span a {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.ResearcherForm .poll .form-respondent .error {
    font-size: 12px;
    color: red;
}

@media (max-width: 768px) {
    .ResearcherForm {
        height: max-content;
        overflow-y: scroll;
        padding: 2% 0;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .ResearcherForm {
        height: max-content;
        overflow-y: scroll;
        padding: 0;
        width: 100%;
        background: rgb(235, 235, 235);
    }

    .ResearcherForm .poll {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    .ResearcherForm .poll .form-respondent {
        border-radius: 0;
        box-shadow: none;
        width: 100%;
    }

    .ResearcherForm .poll .form-respondent .btn-search {
        margin-top: 10px;
        padding: 15px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {


    .ResearcherForm {
        padding: 0;
    }
}