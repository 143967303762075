.blue {
    color: blue;
}

.RespondentForm {
    padding-top: 1%;
    background: linear-gradient(45deg, rgb(160, 160, 245), rgb(1, 1, 63));
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
}

.poll-respondent {
    width: 75%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.form-respondent {
    border-radius: 25px;
    box-shadow: 4px 6px 2px 2px rgb(150, 140, 140);
    padding: 2% 8%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-color: rgb(235, 235, 235);
}

.RespondentForm .poll-respondent .form-respondent .title {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.RespondentForm .poll-respondent .form-respondent .title h4 {
    color: rgb(161, 4, 4);
    width: 100%;
}

.RespondentForm .poll-respondent .form-respondent hr {
    border-top: 0.1px inset rgba(48, 48, 48, 0.534);
    border-bottom: 0.1px inset rgba(48, 48, 48, 0.534);
    width: 100%;
    margin-top: 10px;
}



.RespondentForm .poll-respondent .form-respondent .poll-title hr {
    margin-top: 30px;
}



.RespondentForm .poll-respondent .form-respondent .btn-submit {
    background-color: darkcyan;
    border: 0;
    border-radius: 5px;
}

.RespondentForm .poll-respondent .form-respondent .btn-submit:hover {
    background-color: dodgerblue;
}

.RespondentForm .poll-respondent .form-respondent .btn-search {
    background-color: darkcyan;
    border: 0;
    margin: auto;
    margin-top: 14px;
    width: 100%;
    border-radius: 5px;
}

.RespondentForm .poll-respondent .form-respondent .btn-search:hover {
    background-color: dodgerblue;
}



.RespondentForm .poll-respondent .form-respondent .form-group {
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.RespondentForm .poll-respondent .form-respondent input {
    width: 100%;
    margin-top: 10px;
    padding: 15px;

    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.575);
    user-select: none;
    background-color: rgb(250, 250, 250);

}

.RespondentForm .poll-respondent .form-respondent input :hover {
    border: 2px solid rgba(3, 12, 139, 0.575);
}

.RespondentForm .poll-respondent .form-respondent button {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px 40px 10px 40px;
    color: white
}

.RespondentForm .poll-respondent .form-respondent span a {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.RespondentForm .poll-respondent .form-respondent .error {
    font-size: 12px;
    color: red;
}

@media (min-width: 320px) and (max-width: 480px) {
    .RespondentForm {
        padding: 0;
        width: 100%;
        background: rgb(235, 235, 235);
    }

    .RespondentForm .poll-respondent {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    .RespondentForm .form-respondent {
        border-radius: 0;
        box-shadow: none;
    }

    .RespondentForm .poll-respondent .form-respondent .btn-search {
        margin-top: 10px;
        padding: 15px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {


    .RespondentForm {
        padding: 0;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {}

@media (min-width: 1025px) and (max-width: 1280px) {}

@media (min-width : 1281px) {}