.blue {
    color: blue;
}

.PollForm {
    min-height: 100vh;
    padding-top: 2%;
    background: linear-gradient(45deg, rgb(160, 160, 245), rgb(1, 18, 63));
}

.PollForm .poll {
    width: 60%;
    max-height: 800vh;
    margin: auto;
    border-radius: 25px;
    box-shadow: 4px 6px 2px 2px rgb(150, 140, 140);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);
    padding: 2% 8%;
}

.PollForm .poll .poll-title {
    margin-top: 2px;
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
}

.PollForm .poll .poll-title hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Animation ============================================= */

:root {
    --percentage: 0;
}

/* @keyframes loader {
	0% { width: 0 }
    100% { width: var(--percentage)}
} */

/* Bar ============================================ */
.PollForm .poll .poll-progress-bar {
    width: 60%;
    margin-bottom: 5%;
    height: 3vh;
}

.PollForm .poll .poll-progress-bar span {
    display: flex;
    align-items: center;
}

.PollForm .poll .poll-progress-bar p {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2% 0;
}

.PollForm .bar {
    border-radius: 60px;
    overflow: hidden;
    width: 100%;
    background: rgba(0, 0, 0, 0.075);
}

.PollForm .progress {
    /* animation: loader 3s ease forwards; */
    /* Change the animation fill mode 'infinite' to 'forwards' to stop the animation from repeating. */
    background: #75b800;
    color: #fff;
    padding: 5px;
    /* width: 0; */
    width: var(--percentage);
}

.PollForm .poll-answers {
    display: grid;
    max-height: 30vh;
    overflow-y: auto;
    vertical-align: top;
    margin: 3% 0;
    grid-template-columns: 1fr 1fr;
    width: -webkit-fill-available;
}

/* Oculta a barra de rolagem */
.PollForm .poll-answers::-webkit-scrollbar {
    width: 0.4em;
    background-color: #a6aaaaa1;
    border-radius: 10px;
}

/* Altera a cor do thumb */
.PollForm .poll-answers::-webkit-scrollbar-thumb {
    background-color: #1260c57e;
    border-radius: 10px;
}

.PollForm .poll-answers::-webkit-scrollbar-thumb:active {
    background-color: #000000;
    border-radius: 10px;
}

/* Lickert Scale ========== */

.PollForm .poll-answers-lickert {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: max-content;
    vertical-align: bottom;
    padding: 2% 0;
    margin-bottom: -30px;
}

.PollForm .lickert-options {
    font-size: 12px;
    text-align: center;
    width: 100%;
}

.PollForm .lickert-options label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.PollForm .lickert-options input {
    margin-bottom: 10px;
}

.PollForm .lickert-options input[type="radio"] {
    appearance: none;
}

.PollForm .far {
    text-align: center;
    font-size: 50px;
}

.PollForm .fas {
    text-align: center;
    font-size: 50px;
}

.PollForm .fa-angry {
    color: red;
}

.PollForm .fa-frown {
    color: orange;
}

.PollForm .fa-meh {
    color: #EED202;
}

.PollForm .fa-smile {
    color: #50C878;
}

.PollForm .fa-laugh {
    color: green;
}

.PollForm .fa-meh-blank {
    color: gray;
}

/* Multiple Alternative ========== */

.PollForm .poll .poll-question {
    text-align: center;
    display: flex;
    align-items: center;
    height: max-content;
    width: 100%;
    padding-left: 10px;
    max-height: 50px;
}

.PollForm .poll .question-title h5 {
    margin: 3% 0;
    text-align: center;

}

.PollForm .poll .question-title h4 {
    margin: 3% 0;
    text-align: center;
}

.PollForm .poll .question-title h3 {
    margin: 1.5% 0;
    text-align: center;
}

.PollForm .poll .question-title h2 {
    margin: 5% 0;
    text-align: center;

}

/* Text ========== */

.PollForm .poll .poll-question {
    max-height: max-content;
    display: flex;
    align-items: center;
}

.PollForm .poll .poll-question-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PollForm .poll .poll-question-text input {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    margin-top: 10%;
    border: 1px solid rgba(0, 0, 0, 0.575);
    user-select: none;
    background-color: rgb(250, 250, 250);
}

.poll-question-select select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10%;
    border: 1px solid rgba(0, 0, 0, 0.575);
    user-select: none;
    background-color: rgb(250, 250, 250);
}

.PollForm .poll-answers .poll-registration-data {
    width: 40%;
    justify-content: center;
    margin-bottom: 20px;
}

.PollForm .poll-registration-data .registration-inputs label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.PollForm .registration-inputs a {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
}

.PollForm .registration-inputs input {
    width: 100%;
    height: 25px;
    border: none;
    border-bottom: 1px solid black;
    background-color: #eef1f3;
}

.PollForm .answer-write {
    display: flex;
    flex-direction: column;
}

.PollForm .poll .poll-answers {
    display: grid;
}

.PollForm .poll .poll-answers .poll-question {
    display: flex;
    align-items: center;
    height: max-content;
    padding: 2% 0;
    vertical-align: middle;
    width: 100%;
}

.poll-answers .answer-text {
    margin-left: 5%;
    text-align: left;
    width: 100%;
}

.poll-question .answer-text .answer-check {
    cursor: pointer;
}

.poll-question .answer-text input {
    margin: 0 10px;
    cursor: pointer;
}

.poll-question .answer-text label {
    vertical-align: middle;
}


.poll-control-buttons {
    display: flex;
    flex-direction: row;
    padding-top: 2%;
}

.poll-control-buttons button {
    cursor: pointer;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 15px;
    border: none;
    border-radius: 5px;
    transition: 0.5s;
    color: black;
}

.PollForm .poll-control-preview button {
    background-color: #ff6262;
}

.PollForm .poll-control-preview button:before {
    display: inline-block;
    content: '\279C';
    transform: rotate(180deg);
    position: absolute;
    opacity: 0;
    transition: 0.5s;
    margin-left: -20px;
}

.PollForm .poll-control-preview:hover button {
    padding-left: 25px;
}

.PollForm .poll-control-preview:hover button:before {
    opacity: 1;
    margin-right: 0px;
}

.poll-control-next button {
    background-color: rgb(90, 225, 125);
    margin-bottom: 15px;
}

.PollForm .poll-control-next button:after {
    display: inline-block;
    content: '\279C';
    position: absolute;
    opacity: 0;
    margin-top: -2px;
    margin-left: 5px;
    transition: 0.5s;
}

.PollForm .poll-control-next:hover button {
    padding-right: 25px;
}

.PollForm .poll-control-next:hover button:after {
    opacity: 1;
    margin-right: 0;
}

.PollForm .poll-control-next-finish button {
    background-color: rgb(90, 225, 125);
}

.PollForm .poll-control-next-finish button:after {
    display: inline-block;
    content: '\2714';
    position: absolute;
    opacity: 0;
    margin-top: -2px;
    margin-left: 5px;
    transition: 0.5s;
}

.PollForm .poll-control-next-finish:hover button {
    padding-right: 25px;
}

.PollForm .poll-control-next-finish:hover button:after {
    opacity: 1;
    margin-right: 0;
}

.error {
    font-size: 15px;
    color: red;
    font-weight: bold;
}

.error-span {
    font-size: 16px;
    background-color: rgb(255, 0, 0, 0.45);
    color: black;
    font-weight: 600;
    padding: 5px 20px;
}

/* other-text */

.other-text-radio input {
    cursor: pointer;
    margin: 0 10px;
}

.other-text-check input {
    cursor: pointer;
    margin: 0 10px;
}


.other-text-input {
    width: 55%;
    margin-left: 2%;
    border: 1px solid rgba(0, 0, 0, 0.295);
    padding: 5px 3px;
}

.other-text {
    margin-left: 5%;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}


/* Responsive */

@media (min-width: 320px) and (max-width: 480px) {
    .PollForm {
        padding-top: 0;
        background: white;
    }


    .PollForm .poll {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .PollForm .poll-answers {
        grid-template-columns: 1fr;
        max-height: none;
    }

    .PollForm .lickert-options {
        width: 70%;
        font-size: 9px;
        font-weight: 600;
        text-align: center;
    }

    .PollForm .poll-answers-lickert {
        margin: 5% auto;
    }

    .PollForm .fas {
        font-size: 35px;
        text-align: center;
    }

    .PollForm .far {
        font-size: 35px;
        text-align: center;
    }

    .PollForm .poll .question-title {
        margin: 5% auto 1.5% auto;
    }

    .PollForm .poll .question-title-title {
        font-size: small;
        font-weight: 600;
    }

    .PollForm .poll-question-text {
        margin-bottom: 10%;
    }

}

@media (min-width: 481px) and (max-width: 767px) {
    .PollForm .poll {
        width: 90%
    }

    .PollForm .poll-answers {
        grid-template-columns: 1fr 1fr;
    }

    .PollForm .lickert-options {
        width: 80%;
        font-size: 12px;
        text-align: center;
    }

    .PollForm .fas {
        font-size: 45px;
        text-align: center;
    }

    .PollForm .far {
        font-size: 45px;
        text-align: center;
    }

    .other-text-input {
        width: 40%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .PollForm .poll {
        width: 70%;
        border-radius: 25px;
    }

    .PollForm .lickert-options {
        width: 100%;
        font-size: 12px;
        text-align: center;
    }

    .PollForm .fas {
        font-size: 40px;
        text-align: center;
    }

    .PollForm .far {
        font-size: 40px;
        text-align: center;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .PollForm .poll {
        width: 60%;
        border-radius: 25px;
    }

    .PollForm .lickert-options {
        width: 100%;
        font-size: 16px;
        text-align: center;
    }

    .PollForm .fas {
        font-size: 50px;
        text-align: center;
    }

    .PollForm .far {
        font-size: 50px;
        text-align: center;
    }
}

@media (min-width : 1281px) {
    .PollForm .poll {
        width: 60%;
        border-radius: 25px;
    }

    .PollForm .lickert-options {
        width: 100%;
        font-size: 16px;
        text-align: center;
    }

    .PollForm .fas {
        font-size: 50px;
        text-align: center;
    }

    .PollForm .far {
        font-size: 50px;
        text-align: center;
    }
}